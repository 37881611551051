import './caesar.css'
import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";


const ABC = 'abcdefghijklmnopqrstuvwxyz';
const CHAR_TRANSLATION: Map<string, string> = new Map();
CHAR_TRANSLATION.set('ě', 'e')
CHAR_TRANSLATION.set('š', 's')
CHAR_TRANSLATION.set('č', 'c')
CHAR_TRANSLATION.set('ř', 'r')
CHAR_TRANSLATION.set('ž', 'z')
CHAR_TRANSLATION.set('ý', 'y')
CHAR_TRANSLATION.set('á', 'a')
CHAR_TRANSLATION.set('í', 'i')
CHAR_TRANSLATION.set('é', 'e')
CHAR_TRANSLATION.set(' ', ' ')

let warning = false;

function warn(message: string) {
    if (warning) return;
    const warnElement = document.getElementById('warn') as HTMLDivElement;
    if (!warnElement) return;
    warnElement.textContent = message;
    warnElement.classList.add('warn-active')
    warning = true;
    new Promise(() => {
        setTimeout(() => {
            warnElement.classList.remove('warn-active')
            warning = false;
        }, 2000);
    }).then();
}

function translateChar(char: string): string {
    char = char.toLowerCase();
    if (ABC.includes(char)) return char;
    if (!Array.from(CHAR_TRANSLATION.keys()).includes(char)) {
        warn('Illegal character: ' + char)
        return '';
    }
    const tChar = CHAR_TRANSLATION.get(char);
    if (tChar !== undefined) return tChar;
    return '';
}

function adjustIndex(index: number, length: number) {
    return ((index % length) + length) % length
}

export default function Caesar() {
    const [input, setInput] = useState('');
    const [key, setKey] = useState('');
    const [output, setOutput] = useState('');

    function modInput(inputId: string, value: string) {
        const inputElement = document.getElementById(inputId) as HTMLInputElement;
        if (!inputElement) return;
        inputElement.value = value;
    }

    useEffect(() => {
        function encrypt() {
            let keyIndex = 0;
            let ciphered = ''
            const strippedKey = key.replace(/\s/g, '');
            for (let char of input) {
                if (char === ' ') {
                    ciphered += ' '
                } else {
                    const charABCIndex = ABC.indexOf(char);
                    let keyABCIndex = ABC.indexOf(strippedKey[keyIndex]);
                    keyABCIndex = keyABCIndex === -1 ? 0 : keyABCIndex;
                    let cipheredABCIndex = adjustIndex(charABCIndex + keyABCIndex, ABC.length);
                    ciphered += ABC[cipheredABCIndex]

                    if (keyIndex === strippedKey.length - 1) {
                        keyIndex = 0
                    } else {
                        keyIndex += 1
                    }
                }
            }
            modInput('output', ciphered);
        }

        function decrypt() {
            let keyIndex = 0;
            let deciphered = ''
            const strippedKey = key.replace(/\s/g, '');

            for (let char of output) {
                if (char === ' ') {
                    deciphered += ' '
                } else {
                    const charABCIndex = ABC.indexOf(char);
                    let keyABCIndex = ABC.indexOf(strippedKey[keyIndex]);
                    if (keyABCIndex === -1) keyABCIndex = 0;
                    const decipheredABCIndex = adjustIndex(charABCIndex - keyABCIndex, ABC.length);
                    deciphered += ABC[decipheredABCIndex]

                    if (keyIndex === strippedKey.length - 1) {
                        keyIndex = 0
                    } else {
                        keyIndex += 1
                    }
                }
            }
            modInput('input', deciphered)
        }

        if (input === '' && output === '') {
            modInput('input', '');
            modInput('output', '');
        } else if (output === '') {
            encrypt();
        } else if (input === '') {
            decrypt();
        }
    }, [input, key, output]);

    function inputChange(event: React.ChangeEvent<HTMLInputElement>) {
        let translated = ''
        for (let ch of event.target.value) {
            translated += translateChar(ch);
        }
        event.target.value = translated;
        setOutput('')
        setInput(translated);
    }

    function keyChange(event: React.ChangeEvent<HTMLInputElement>) {
        let translated = ''
        for (let char of event.target.value) {
            translated += translateChar(char);
        }
        event.target.value = translated;
        setKey(event.target.value);
    }

    function outputChange(event: React.ChangeEvent<HTMLInputElement>) {
        let translated = ''
        for (let ch of event.target.value) {
            translated += translateChar(ch);
        }
        event.target.value = translated;
        setInput('')
        setOutput(event.target.value);
    }

    return (
        <>
            <Helmet>
                <title>Caesar+</title>
            </Helmet>
            <div className={'title'}>
                <h1>Caesar cipher+</h1>
            </div>
            <div className={'caesar'}>
                <div className={'entry'}>
                    <h2>Decrypted: </h2>
                    <input id={'input'} className={'input'} onChange={inputChange}/>
                </div>
                <div className={'entry'}>
                    <h2>Key: </h2>
                    <input id={'key'} className={'key'} onChange={keyChange}/>
                </div>
                <div className={'entry'}>
                    <h2>Encrypted: </h2>
                    <input id={'output'} className={'output'} onChange={outputChange}/>
                </div>
            </div>
            <div className={'warn'} id={'warn'}></div>
        </>
    )
}