import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Caesar from './routes/caesar/caesar';

export default function Page() {
    return (
        <Router>
            <Routes>
                <Route path={'/'}></Route>
                <Route path={'/caesar'} element={<Caesar/>}></Route>
            </Routes>
        </Router>
    )
}